var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "flowContainer",
      staticStyle: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      },
    },
    [
      _vm.isShowContent
        ? _c(
            "div",
            { staticClass: "states-box" },
            _vm._l(_vm.states, function (item) {
              return _c("span", { key: item.type, staticClass: "state-item" }, [
                _c("span", { class: item.className }),
                _c("p", [_vm._v(_vm._s(item.name))]),
              ])
            }),
            0
          )
        : _vm._e(),
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "flow-container",
            class: {
              zoomIn: _vm.currentTool.type == "zoom-in",
              zoomOut: _vm.currentTool.type == "zoom-out",
              canScale: _vm.container.scaleFlag,
              canDrag: _vm.container.dragFlag,
              canMultiple: _vm.rectangleMultiple.flag,
            },
            staticStyle: { cursor: "pointer" },
            style: {
              top: _vm.container.pos.top + "px",
              left: _vm.container.pos.left + "px",
              transform: "scale(" + _vm.container.scale + ")",
              transformOrigin:
                _vm.container.scaleOrigin.left +
                "px " +
                _vm.container.scaleOrigin.top +
                "px",
              "z-index": _vm.isDrag ? 11 : -2,
            },
            attrs: { id: "toDraggable", tag: "div" },
            on: { end: _vm.handleMoveEnd, add: _vm.handleAddFormItem },
            model: {
              value: _vm.flowData.nodes,
              callback: function ($$v) {
                _vm.$set(_vm.flowData, "nodes", $$v)
              },
              expression: "flowData.nodes",
            },
          },
          "draggable",
          { group: "flow", animation: 200 },
          false
        )
      ),
      _c(
        "div",
        {
          ref: "flowContainers",
          staticClass: "flow-container",
          class: {
            grid: _vm.flowData.config.showGrid,
            zoomIn: _vm.currentTool.type == "zoom-in",
            zoomOut: _vm.currentTool.type == "zoom-out",
            canScale: _vm.container.scaleFlag,
            canDrag: _vm.container.dragFlag,
            canMultiple: _vm.rectangleMultiple.flag,
          },
          staticStyle: { cursor: "pointer" },
          style: {
            top: _vm.container.pos.top + "px",
            left: _vm.container.pos.left + "px",
            transform: "scale(" + _vm.container.scale + ")",
            transformOrigin:
              _vm.container.scaleOrigin.left +
              "px " +
              _vm.container.scaleOrigin.top +
              "px",
          },
          attrs: { id: "flowContainer" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.containerHandler.apply(null, arguments)
            },
            mousedown: _vm.mousedownHandler,
            mousemove: _vm.mousemoveHandler,
            mouseup: _vm.mouseupHandler,
            mousewheel: _vm.scaleContainer,
            DOMMouseScroll: _vm.scaleContainer,
            contextmenu: _vm.showContainerContextMenu,
          },
        },
        [
          _vm._l(_vm.flowData.nodes, function (node) {
            return [
              node && node.id
                ? _c("flow-node", {
                    key: node.id,
                    attrs: {
                      node: node,
                      plumb: _vm.plumb,
                      select: _vm.currentSelect,
                      selectGroup: _vm.currentSelectGroup,
                      currentTool: _vm.currentTool,
                      isShowContent: _vm.isShowContent,
                    },
                    on: {
                      "update:select": function ($event) {
                        _vm.currentSelect = $event
                      },
                      "update:selectGroup": function ($event) {
                        _vm.currentSelectGroup = $event
                      },
                      "update:select-group": function ($event) {
                        _vm.currentSelectGroup = $event
                      },
                      showNodeContextMenu: _vm.showNodeContextMenu,
                      isMultiple: _vm.isMultiple,
                      updateNodePos: _vm.updateNodePos,
                      alignForLine: _vm.alignForLine,
                      hideAlignLine: _vm.hideAlignLine,
                    },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c("vue-context-menu", {
        attrs: { contextMenuData: _vm.containerContextMenuData },
        on: {
          paste: _vm.paste,
          selectAll: _vm.selectAll,
          saveFlow: _vm.saveFlow,
          verticaLeft: _vm.verticaLeft,
          verticalCenter: _vm.verticalCenter,
          verticalRight: _vm.verticalRight,
          levelUp: _vm.levelUp,
          levelCenter: _vm.levelCenter,
          levelDown: _vm.levelDown,
        },
      }),
      _c("vue-context-menu", {
        attrs: { contextMenuData: _vm.nodeContextMenuData },
        on: { copyNode: _vm.copyNode, deleteNode: _vm.deleteNode },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }