var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    { attrs: { disabled: _vm.isDisabled(), content: "点击关闭 tooltip 功能" } },
    [
      _vm.contentHtml
        ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.contentHtml) } }),
          ])
        : _vm._e(),
      _vm.node.type == "start round mix"
        ? _c(
            "div",
            {
              staticClass: "common-circle-node node-start-bg node-start",
              class: { active: _vm.isActive() },
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                cursor:
                  _vm.currentTool.type == "drag"
                    ? "move"
                    : _vm.currentTool.type == "connection"
                    ? "crosshair"
                    : _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: { id: _vm.node.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectNode.apply(null, arguments)
                },
                contextmenu: function ($event) {
                  $event.stopPropagation()
                  return _vm.showNodeContextMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { class: "iconfont icon-StartOP" }),
              _vm._v(" " + _vm._s(_vm.node.name) + " "),
            ]
          )
        : _vm.node.type == "end round"
        ? _c(
            "div",
            {
              staticClass: "common-circle-node node-end",
              class: { active: _vm.isActive() },
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                cursor:
                  _vm.currentTool.type == "drag"
                    ? "move"
                    : _vm.currentTool.type == "connection"
                    ? "crosshair"
                    : _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: { id: _vm.node.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectNode.apply(null, arguments)
                },
                contextmenu: function ($event) {
                  $event.stopPropagation()
                  return _vm.showNodeContextMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { class: "iconfont icon-End" }),
              _vm._v(" " + _vm._s(_vm.node.name) + " "),
            ]
          )
        : _vm.node.type === "node"
        ? _c(
            "div",
            {
              staticClass: "common-rectangle-node flex-row",
              class: _vm.stateClass(),
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                cursor:
                  _vm.currentTool.type == "drag"
                    ? "move"
                    : _vm.currentTool.type == "connection"
                    ? "crosshair"
                    : _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: { id: _vm.node.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectNode.apply(null, arguments)
                },
                contextmenu: function ($event) {
                  $event.stopPropagation()
                  return _vm.showNodeContextMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("el-icon", {
                class:
                  _vm.node.icon || _vm.node.defaultIcon || "el-icon-s-tools",
              }),
              _c("span", { staticClass: "flex-item" }, [
                _vm._v(_vm._s(_vm.node.name)),
              ]),
            ],
            1
          )
        : _vm.node.type === "fork"
        ? _c(
            "div",
            {
              staticClass: "common-rectangle-node flex-row",
              class: _vm.stateClass(),
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                cursor:
                  _vm.currentTool.type == "drag"
                    ? "move"
                    : _vm.currentTool.type == "connection"
                    ? "crosshair"
                    : _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: { id: _vm.node.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectNode.apply(null, arguments)
                },
                contextmenu: function ($event) {
                  $event.stopPropagation()
                  return _vm.showNodeContextMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("span", {
                class:
                  _vm.node.icon || _vm.node.defaultIcon || "iconfont icon-fork",
              }),
              _c("span", { staticClass: "flex-item" }, [
                _vm._v(_vm._s(_vm.node.name)),
              ]),
            ]
          )
        : _vm.node.type === "join"
        ? _c(
            "div",
            {
              staticClass: "common-rectangle-node flex-row",
              class: _vm.stateClass(),
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                cursor:
                  _vm.currentTool.type == "drag"
                    ? "move"
                    : _vm.currentTool.type == "connection"
                    ? "crosshair"
                    : _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: { id: _vm.node.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectNode.apply(null, arguments)
                },
                contextmenu: function ($event) {
                  $event.stopPropagation()
                  return _vm.showNodeContextMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("span", {
                class:
                  _vm.node.icon ||
                  _vm.node.defaultIcon ||
                  "iconfont icon-gaibanxianxingtubiao-",
              }),
              _c("span", { staticClass: "flex-item" }, [
                _vm._v(_vm._s(_vm.node.name)),
              ]),
            ]
          )
        : _vm.node.type == "common"
        ? _c(
            "div",
            {
              staticClass: "common-rectangle-node flex-row",
              class: _vm.stateClass(),
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                cursor:
                  _vm.currentTool.type == "drag"
                    ? "move"
                    : _vm.currentTool.type == "connection"
                    ? "crosshair"
                    : _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: { id: _vm.node.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectNode.apply(null, arguments)
                },
                contextmenu: function ($event) {
                  $event.stopPropagation()
                  return _vm.showNodeContextMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("el-icon", { class: _vm.node.icon || _vm.node.defaultIcon }),
              _c("span", { staticClass: "flex-item" }, [
                _vm._v(_vm._s(_vm.node.name)),
              ]),
            ],
            1
          )
        : _vm.node.type == "freedom"
        ? _c(
            "div",
            {
              staticClass: "common-rectangle-node",
              class: _vm.stateClass(),
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                cursor:
                  _vm.currentTool.type == "drag"
                    ? "move"
                    : _vm.currentTool.type == "connection"
                    ? "crosshair"
                    : _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: { id: _vm.node.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectNode.apply(null, arguments)
                },
                contextmenu: function ($event) {
                  $event.stopPropagation()
                  return _vm.showNodeContextMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-refresh" }),
              _vm._v(" " + _vm._s(_vm.node.name) + " "),
            ]
          )
        : _vm.node.type == "event"
        ? _c(
            "div",
            {
              staticClass: "common-circle-node",
              class: _vm.stateClass(),
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                cursor:
                  _vm.currentTool.type == "drag"
                    ? "move"
                    : _vm.currentTool.type == "connection"
                    ? "crosshair"
                    : _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: { id: _vm.node.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectNode.apply(null, arguments)
                },
                contextmenu: function ($event) {
                  $event.stopPropagation()
                  return _vm.showNodeContextMenu.apply(null, arguments)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.node.name) + " ")]
          )
        : _vm.node.type == "gateway"
        ? _c("div", {
            staticClass: "common-diamond-node",
            class: _vm.stateClass(),
            style: {
              top: _vm.node.top + "px",
              left: _vm.node.left + "px",
              cursor:
                _vm.currentTool.type == "drag"
                  ? "move"
                  : _vm.currentTool.type == "connection"
                  ? "crosshair"
                  : _vm.currentTool.type == "zoom"
                  ? "zoom-in"
                  : _vm.currentTool.type == "zoom-out"
                  ? "zoom-out"
                  : "default",
            },
            attrs: { id: _vm.node.id },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.selectNode.apply(null, arguments)
              },
              contextmenu: function ($event) {
                $event.stopPropagation()
                return _vm.showNodeContextMenu.apply(null, arguments)
              },
            },
          })
        : _vm.node.type == "child-flow"
        ? _c(
            "div",
            {
              staticClass: "common-rectangle-node",
              class: _vm.stateClass(),
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                cursor:
                  _vm.currentTool.type == "drag"
                    ? "move"
                    : _vm.currentTool.type == "connection"
                    ? "crosshair"
                    : _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: { id: _vm.node.id },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectNode.apply(null, arguments)
                },
                contextmenu: function ($event) {
                  $event.stopPropagation()
                  return _vm.showNodeContextMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("a-icon", {
                staticClass: "node-icon",
                attrs: { type: "api" },
              }),
              _vm._v(" " + _vm._s(_vm.node.name) + " "),
            ],
            1
          )
        : _vm.node.type == "x-lane"
        ? _c(
            "vdr",
            {
              staticClass: "common-x-lane-node",
              class: { laneActive: _vm.isActive() },
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                height: _vm.node.height + "px",
                width: _vm.node.width + "px",
                cursor:
                  _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: {
                id: _vm.node.id,
                w: _vm.node.width,
                h: _vm.node.height,
                parent: true,
              },
              on: { resizing: _vm.onResize },
            },
            [
              _c(
                "div",
                {
                  staticClass: "lane-text-div",
                  style: {
                    top: 0,
                    left: 0,
                    cursor: _vm.currentTool.type == "drag" ? "move" : "default",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.selectNode.apply(null, arguments)
                    },
                    contextmenu: function ($event) {
                      $event.stopPropagation()
                      return _vm.showNodeContextMenu.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "lane-text" }, [
                    _vm._v(_vm._s(_vm.node.name)),
                  ]),
                ]
              ),
            ]
          )
        : _vm.node.type == "y-lane"
        ? _c(
            "vdr",
            {
              staticClass: "common-y-lane-node",
              class: { laneActive: _vm.isActive() },
              style: {
                top: _vm.node.top + "px",
                left: _vm.node.left + "px",
                height: _vm.node.height + "px",
                width: _vm.node.width + "px",
                cursor:
                  _vm.currentTool.type == "zoom-in"
                    ? "zoom-in"
                    : _vm.currentTool.type == "zoom-out"
                    ? "zoom-out"
                    : "default",
              },
              attrs: { id: _vm.node.id, w: _vm.node.width, h: _vm.node.height },
              on: { resizing: _vm.onResize },
            },
            [
              _c(
                "div",
                {
                  staticClass: "lane-text-div",
                  style: {
                    cursor: _vm.currentTool.type == "drag" ? "move" : "default",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.selectNode.apply(null, arguments)
                    },
                    contextmenu: function ($event) {
                      $event.stopPropagation()
                      return _vm.showNodeContextMenu.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "lane-text" }, [
                    _vm._v(_vm._s(_vm.node.name)),
                  ]),
                ]
              ),
            ]
          )
        : _c("div"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }